import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AboutSection } from "../components/about/about-section/AboutSection";
import { Why } from "../components/about/why-section/Why";
import BreadCrumbs from "../components/breadcrumbs/Breadcrumbs";
import SEO from "../components/SEO";

function About() {
  return (
    <>
      <SEO
        title="About - KIE"
        description="A deeper look into who we are, how we operate and how we do what we do here at the KIE."
        url="https://govtkoreaimmigration.com/about"
        image="/about-img.png"
      />
      <BreadCrumbs current="About Us" />
      <AboutSection />
      <hr />
      <Why />
    </>
  );
}

export default About;
