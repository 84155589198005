// SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, url, image }) => (
  <Helmet>
    {/* Basic SEO */}
    <title>{title}</title>
    <meta name="description" content={description} />

    {/* Open Graph tags for social media */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />

    {/* Twitter Card tags for social media */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />

    {/* Viewport settings */}
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    {/* Canonical URL */}
    <link rel="canonical" href={url} />

    {/* Additional meta tags */}
    <meta charSet="UTF-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="robots" content="index, follow" />
    <link rel="icon" href='/favicon.png' type="image/png" />
    <link rel="apple-touch-icon" href='/favicon.png' />
  </Helmet>
);

export default SEO;