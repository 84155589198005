import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BreadCrumbs from "../components/breadcrumbs/Breadcrumbs";
import { WhyKoreaOne } from "../components/whyKorea/WhyKorea";
import SEO from "../components/SEO";

function WhyKorea() {
    return (
        <>
        <SEO
        title="Why Korea? - KIE"
        description="South Korea boasts one of the most robust economies in Asia, known for its rapid growth and technological innovation. The country offers abundant career opportunities in various fields including technology, automotive, and consumer electronics."
        url="https://govtkoreaimmigration.com/why-korea"
        image="/image1.png"
      />
        <BreadCrumbs current="Why Korea?" />
        <WhyKoreaOne />
        </>
    );
}

export default WhyKorea;