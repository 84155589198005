import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BreadCrumbs from "../components/breadcrumbs/Breadcrumbs";
import { VisaProgram } from "../components/visaPrograms/VisaPrograms";
import SEO from "../components/SEO";

function VisaPrograms() {
  return (
    <>
      <SEO
        title="VISA Programs - KIE"
        description="A basic list of the VISA services available and the various basic
requirements."
        url="https://govtkoreaimmigration.com/visa-programs"
        image="/work.png"
      />
      <BreadCrumbs current="VISA Programs" />
      <VisaProgram />
    </>
  );
}

export default VisaPrograms;
